<script setup>
    // AddPaymentMethodStripe
    // Allows the creation of a payment method.

    // Imports ----
    import { store } from '@dd-nucleus/nucleus-vue';
    import { computed, defineProps, defineEmits, reactive, ref, onBeforeMount, onMounted } from 'vue';
    import { site, DEFAULT_COUNTRY_CODE } from '@/Site';
    import { StripeElements, StripeElement } from 'vue-stripe-js'
    import { loadStripe } from '@stripe/stripe-js'
    import { PaymentTypeCode } from '../../services/PaymentInfo';

    // Props ----
    const props = defineProps({
        typeCode: {
            type: String,
            required: false,
            default: PaymentTypeCode.CREDIT_CARD
        },
        form: {
            type: Object,
            required: true
        },
    });


    // Computed
    const userData = computed(() => {
        return {
            metadata: { 'userId': site.user.userId },
            name: site.user.firstName + ' ' + site.user.lastName
        }
    });

    const countries = computed(() => {
        return site?.config?.store?.countries || [];
    });

    const isCreateCustomer = computed(() => {
        return !site.paymentInfo.stripeCustomerId.length > 0;
    });



    // State ----
    const form = reactive(props.form);

    const selectedCountry = ref(DEFAULT_COUNTRY_CODE);
    // TODO: get this from env variables
    const stripeKey = process.env.VUE_APP_STRIPE_API_PUBLISH_KEY;

    const cardOptions = ref({
        // https://stripe.com/docs/stripe.js#element-options
        value: {
            postalCode: '',
        },
        style: {
            base: {
                iconColor: '#05040d',
                color: '#1a1446',
                fontWeight: '500',
                fontSize: '20px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#fce883',
                },
                '::placeholder': {
                    color: '#29254f',
                },
            },
            invalid: {
                iconColor: '#FFC7EE',
                color: '#FFC7EE',
            },
        },
    });

    const stripeLoaded = ref(false);

    const card = ref();

    const elms = ref();

    const customer = reactive({});

    const paymentMethod = reactive({});

    // Handlers ----
    const emit = defineEmits(['generateToken', 'change', 'cancel']);

    // Third we need to attach the payment method to the customer
    async function attachPaymentMethod() {

        const response = await site.paymentInfo.attachStripeCustomerPaymentMethod(paymentMethod.paymentMethod.id, customer.id, form.model.isDefault);

        if (response && response.succeeded) {
            emit('generateToken', response);
        }
        else {
            if (response && response.data && response.data.error && response.data.error.message) {
                site.toast.error(response.data.error.message, 'Card Error');
            }
            else {
                site.toast.error(response, 'Not able to attach the CC to your account. Please contact system administrator.');
            }
            return false;
        }
    }

    // First we first need to create a customer for stripe
    async function createCustomer() {
        if (form.model.addressLine1.length > 30) {
            form.validator.fields.addressLine1.addWarning('Address Line 1 cannot be more than 30 characters');
        }
        else {

            if (isCreateCustomer.value)
                Object.assign(customer, await site.paymentInfo.createStripeCustomer(userData.value));
            else
                customer.id = site.paymentInfo.stripeCustomerId;

            await createPaymentMethod();
        }

    }

    // Second we need to create a Payment Method
    async function createPaymentMethod() {
        // Get stripe element
        const cardElement = card.value.stripeElement;

        await elms.value.instance.createPaymentMethod(
            {
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: form.model.name,
                    address: {
                        line1: form.model.addressLine1,
                        line2: form.model.addressLine2,
                        city: form.model.city,
                        state: form.model.state,
                        zip: form.model.postalCode,
                        country: form.model.country
                    }
                }
            }
        ).then((method) => {
            if (method.error) {
                site.toast.error(method.error.message, 'Card Error');
                return false;
            }

            Object.assign(paymentMethod, method);
            // Third step
            attachPaymentMethod();

        });
    }

    function onChanged() {
        emit('change');
    }

    function onCancel() {
        emit('cancel');
    }

    function onBillingAddressChanged() {
        selectedCountry.value = form.model.country;      
    }
    
    // Lifecycle ----

    onMounted(async () => {
        selectedCountry.value = form.model.Country;
    });

    onBeforeMount(() => {
        const stripePromise = loadStripe(stripeKey);
        stripePromise.then(() => {
            stripeLoaded.value = true
        });
    });
</script>

<template>
    <div class="n-add-payment-method">

        <FormContainer :form="form" optional-text="(Optional)">
            <FormMessages />
            <slot name="top"></slot>

            <div class="row">
                <div class="col">
                    <h4>Credit Card Information</h4>
                </div>
            </div>

            <StripeElements v-if="stripeLoaded"
                            v-slot="{ elements }"
                            ref="elms"
                            :stripe-key="stripeKey">
                <StripeElement ref="card"
                               :elements="elements"
                               :options="cardOptions" />
            </StripeElements>

            <div class="row mt-5 mb-3">
                <div class="col">
                    <h4>Billing Information</h4>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="name" label="Name on Card">
                        <TextBoxField v-model="form.model.name" placeholder="Name on Card" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="mb-3">
                <FieldLabel field-name="country" label="Country">
                    <DropDownListField v-model="form.model.country" :options="countries" null-option-text="Select Country" :selectedCountry="selectedCountry" @change="onBillingAddressChanged" />
                    <FieldMessages />
                </FieldLabel>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="addressLine1" label="Street Address">
                        <TextBoxField v-model="form.model.addressLine1" placeholder="Street Address" @change="onChanged" />
                        <FieldMessages />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="addressLine2" label="Address Line 2">
                        <TextBoxField v-model="form.model.addressLine2" placeholder="Suite, etc." @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="city" label="City">
                        <TextBoxField v-model="form.model.city" placeholder="City" @change="onChanged" />
                        <FieldMessages />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="state" label="State">
                        <DropDownListField v-model="form.model.state" :options="store.shipping.states" null-option-text="Select State" @change="onChanged" />
                        <FieldMessages />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <CheckBoxField v-model="form.model.isDefault" @change="onChanged">
                        <i class="bi bi-star-fill text-warning"></i> Save as my default credit card
                    </CheckBoxField>
                    <FieldMessages field-name="isDefault" />
                </div>
            </div>

            <div class="col-12 mt-3">
                <button class="btn btn-outline-color-1 me-2" @click="onCancel()">Cancel</button>
                <button class="btn btn-color-1" @click="createCustomer()" :disabled="form.status.isInvalid">Create</button>
            </div>

        </FormContainer>

    </div>
</template>

<style lang="scss">
</style>