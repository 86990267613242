<script setup>
    import { onMounted, reactive } from 'vue';
    import { site } from '@/Site';
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';
    import { isNotEmpty, formatDateTime } from '@/util';

    const savedItems = reactive({ orderStates: [] });

    async function deleteOrderState(id) {
        const response = await site.personalizationInfo.deleteOrderState(id);
        if (response.succeeded) {
            const toDeleteOrderState = savedItems.orderStates.find(os => os.id = id);
            savedItems.orderStates = savedItems.orderStates.filter((item) => item.id != id);
            if (isNotEmpty(toDeleteOrderState.parentId)) {
                if (toDeleteOrderState.parentId == id) {
                    savedItems.orderStates = savedItems.orderStates.filter(item => item.parentId != id);
                } else if (toDeleteOrderState.parentId != id) {
                    savedItems.orderStates = savedItems.orderStates.filter(item => item.id != toDeleteOrderState.parentId);
                }
            }
        }
    }

    function resumeOrderState(orderState) {
        const parentId = orderState.parentId ? orderState.parentId : orderState.id;
        const orderStateId = orderState.parentId ? orderState.parentId : orderState.id;
        const channelType = orderState.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${orderStateId}/${parentId}/${orderState.productId}/?edit=true&isSubscription=${orderState.product?.attributes?.isSubscription}&channelType=${channelType}`;
    }


    async function getSavedItems() {
        savedItems.orderStates.length = 0;
        const response = await site.personalizationInfo.getSavedItems();
        if (response && response.length > 0) {
            savedItems.orderStates = response;
        }
    }

    onMounted(async () => {
        await getSavedItems();
    })

</script>

<template>
    <div class="account-panel agency-lists-panel">
        <h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Saved Items</span></h1>

        <div class="save-for-later data-table table-responsive">
            <table class="n-search-table table table-striped">
                <thead>
                    <tr class="bg-color-3-lt">
                        <th class="border-end border-gray-0">Asset Number</th>
                        <th class="border-end border-gray-0">Asset Name</th>
                        <th class="border-end border-gray-0">Date Created</th>
                        <th class="border-end border-gray-0">Date Revisioned</th>
                        <th class="border-end border-gray-0">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="item in savedItems.orderStates" :key="item.id" class="border-bottom border-gray-2">
                        <td>{{ item.product.itemNumber }}</td>
                        <td>{{ item.product.name }}</td>
                        <td>{{ formatDateTime(item.created) }}</td>
                        <td>{{ formatDateTime(item.updated) }}</td>
                        <td>
                            <div class="actions">
                                <router-link :to="resumeOrderState(item)" class="resume">Resume</router-link>
                                <p @click="deleteOrderState(item.id)" class="delete">Delete</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .save-for-later {
        .table > :not(caption) > * > * {
            border-bottom: unset;
        }
        // Another weird border top on the table
        .table > :not(:first-child) {
            border-top: unset;
        }

        thead {
            background: $color-2;
            color: $gray-4;
            border-color: $gray-0;
        }

        th {
            color: white;
            background-color: #06748c;
            border: 2px solid #ccc;
            padding: 10px;
            text-align: center;
        }

       

        tr {
            border-color: #c0bfc0;
            border-bottom: 1px solid #dee2e6;

            &:last-child {
                border-bottom: unset;
            }

            td {
                width: 26%;
                padding: 8px !important;
                vertical-align: middle;
                text-align: center;
            }

        }

        .actions {
            display: flex;

            .resume {
                cursor: pointer;
                font-size: 14px;
                align-self: center;
                vertical-align: middle;
                color: #065baa;
                margin: 0px;
                text-decoration: none !important;
            }

            .delete {
                cursor: pointer;
                font-size: 14px;
                align-self: center;
                vertical-align: middle;
                margin: 0px;
                margin-left: 12px;
                color: red;
            }
        }
    }
</style>