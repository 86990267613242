<script setup>

    // ShipToUser
    // Allows the user to ship to a new address, pick from an existing address, or edit existing addresses, if site options for doing so are enabled.

    // Constants ----
    const View = {
        EDITABLE: 'EDITABLE',
        EDITING: 'EDITING',
        SUMMARY: 'SUMMARY'
    }

    const AddressOption = {
        SAVED_ADDRESS: 'SAVED',
        OTHER: 'OTHER'
    }

    // Components ----
    import ShippingAddressTile from '@/site/components/ShippingAddressTile';

    // Imports ----
    import { reactive, ref, defineProps, computed, onMounted, watch, defineEmits } from 'vue';
    import { site, DEFAULT_COUNTRY_CODE } from '@/Site';
    import ShipToUserForm from '../forms/ShipToUserForm';
    import { Search, Util } from '@dd-nucleus/nucleus-vue';

    // Props ----
    const props = defineProps({
        // The label to show with the icon on the button.
        canEdit: {
            type: Boolean,
            required: true
        }
    });

    // State ----
    const form = reactive(new ShipToUserForm());
    const cart = reactive(site.cart.current);
    const addressOption = ref('');
    const selectedAddressId = ref('');
    const defaultAddressId = ref(site.shipping.addresses.length > 0 ? site.shipping.addresses[0].id : '');
    const isLoaded = ref(false);
    const selectedCountry = ref(DEFAULT_COUNTRY_CODE);

    const search = new Search('addresses-for-user', 'tile', 'address-sequence', 1000);
    search.setEmptyFilter('address-shipping');

    const options = [
        { id: AddressOption.SAVED_ADDRESS, text: 'Saved Address' },
        { id: AddressOption.OTHER, text: 'Add New Shipping Address' }
    ];

    // Computed ----
    const pageView = computed(() => {
        if (props.canEdit) {
            return View.EDITING;
        }
        else
            // Summary view - display only
            return View.SUMMARY;
    })

    const cartAddressOption = computed(() => {
        return addressOption.value;
    });

    // Watch ----
    watch(cartAddressOption, (newValue) => {
        if (newValue === "OTHER")
            validateForm();
        else if (defaultAddressId.value) {
            onAddressSelected({ id: defaultAddressId.value });
        }
        else
            emit('hasShippingDetails', true);
    })

    // Events ----
    const emit = defineEmits(['hasShippingDetails']);

    // Handlers ----
    onMounted(() => {
        update();
        if (selectedAddressId.value && cart.shipToMode === site.cart.ShipToMode.ShipToUser)
            onAddressSelected({ id: selectedAddressId.value });
    });


    // Called once we know we have a cart to work with
    function update() {
        if (Util.isEmpty(site.cart.current.shipToList)) return;

        if (cart.shipToMode === site.cart.ShipToMode.ShipToUser && cart.shipToList[0].contactAddressId === null) {
            addressOption.value = AddressOption.OTHER;

            const shipTo = site.cart.current.shipToList[0];
            form.model.companyName = shipTo.companyName;
            form.model.addressLine1 = shipTo.addressLine1;
            form.model.addressLine2 = shipTo.addressLine2;
            form.model.addressLine3 = shipTo.addressLine3;
            form.model.addressLine4 = shipTo.addressLine4;
            form.model.city = shipTo.city;
            form.model.state = shipTo.state;
            form.model.postalCode = shipTo.postalCode;
            form.model.countryCode = shipTo.countryCode ? shipTo.countryCode : selectedCountry.value;

            selectedAddressId.value = ''
            validateForm();
        }
        else {
            addressOption.value = AddressOption.SAVED_ADDRESS;
            selectedAddressId.value = Util.isEmpty(defaultAddressId.value) ? '' : defaultAddressId.value;
        }


        isLoaded.value = true;
    }

    async function onAddressSelected(address) {
        await site.cart.setShipToUserSavedAddress(address.id);
        selectedAddressId.value = address.id;
        emit('hasShippingDetails', false);
        site.refreshSearches('addresses-for-user');
    }

    async function onOtherAddressChanged() {
        if (selectedCountry.value != form.model.countryCode) {
            selectedCountry.value = form.model.countryCode;
            form.model.state = '';
            form.model.province = '';
        }
        if (form.model.addressLine1.length > 30) {
            form.validator.fields.addressLine1.addWarning('Address Line 1 cannot be more than 30 characters');
        }
        else {
            await form.submit(true);
            validateForm();
        }
    }

    function validateForm() {
        form.validate();
        if (!form.status.isInvalid)
            emit('hasShippingDetails', false);
        else {
            emit('hasShippingDetails', true);
        }
    }

</script>

<template>

    <div class="ship-to-user">

        <div v-if="pageView === View.EDITABLE" class="p-3">

            <ShippingAddressTile v-if="isLoaded" :address="site.cart.current.shipToList[0]" :border="false">
            </ShippingAddressTile>

        </div>

        <template v-if="pageView === View.EDITING">
            <div class="mb-3">
                <RadioButton v-for="option in options" :key="option.id" v-model="addressOption" :label="option.text" list-id="address-option" :value="option.id" class="d-inline-block me-3" />
            </div>

            <div v-if="cartAddressOption === AddressOption.SAVED_ADDRESS">
                <SearchContainer :search="search" :deep-link="false">

                    <SearchGrid :columns-xs="1" :columns-sm="2" :columns-md="2" :columns-lg="3" :columns-xl="3" :columns-xxl="3">
                        <!-- Template for each item in a grid view -->
                        <template v-slot:grid="address">

                            <TileSelectButton :item="address" :multi-select="false" :is-selected="address.id === selectedAddressId" @selected="onAddressSelected(address)" />
                            <ShippingAddressTile :class="{ selected: address.id === selectedAddressId }" :address="address" :can-set-default="true" height="14rem">
                            </ShippingAddressTile>

                        </template>
                    </SearchGrid>

                </SearchContainer>
            </div>

            <div v-if="cartAddressOption === AddressOption.OTHER">
                <div class="col-12 col-md-9 col-lg-6">
                    <EditShippingAddress :form="form" @change="onOtherAddressChanged" :selectedCountry="selectedCountry">
                        <template #top>
                            <div class="n-form-label mb-2">
                                <label for="61d18332-3ce4-487f-bc66-b24b0e5a579b" class="label form-label">Ship To</label>
                                <div class="n-text-box-field">
                                    <input id="61d18332-3ce4-487f-bc66-b24b0e5a579b" type="text" class="text-box form-control" :value="site.user.firstName + ' ' + site.user.lastName" disabled>
                                </div>
                            </div>
                        </template>
                        <div class="mt-3">
                            <CheckBoxField v-model="form.model.saveAddress" @change="onOtherAddressChanged" label="Save this address for future orders" />
                            <CheckBoxField v-if="form.model.saveAddress" v-model="form.model.saveDefault" @change="onOtherAddressChanged" label="Save as my default shipping address" />
                        </div>
                    </EditShippingAddress>
                </div>
            </div>
        </template>

        <template v-if="pageView === View.SUMMARY">

            <ShippingAddressTile v-if="isLoaded" :address="site.cart.current.shipToList[0]" :border="false">
            </ShippingAddressTile>

        </template>
    </div>

</template>

<style lang="scss">

    .ship-to-user {
        .n-tile-select-button {
            position: absolute;
            margin-top: -0.5rem;
            margin-left: -0.25rem
        }

        .shipping-address-tile.selected .n-address-tile {
            border: 1px solid $color-1 !important;
        }
    }
</style>