<script setup>

    // CrossSellViewSummaryPage
    // View the lists for cross sell opportunites

    // Constants ----
    const constants = {
        DEFAULT_PAGE_SIZE: 50
    }

    const pageSizeOptions = [25, 50, 100, 200];

    const columns = [
        { field: 'actions', title: 'Actions', custom: true },
        { field: 'opportunities', title: 'Opportunities', isSortable: true },
        { field: 'subCode', title: 'Sub Code', isSortable: true, custom: true, formatter: (value) => formatStatCode(value) },
        { field: 'agencyName', title: 'Agency Name', isSortable: true },
        { field: 'agencyAddress', title: 'Agency Address', custom: true, isSortable: true },
        { field: 'phone', title: 'Phone #', isSortable: true }
    ];

    // Import ----
    import { computed, ref } from 'vue';
    import { Search } from '@dd-nucleus/nucleus-vue';
    import { useRoute, useRouter } from 'vue-router';
    import { ExportParser } from "@/site/services/ExportParser";
    import { crossSellIds,productTypes,crossSellTypes, getCrossSellType } from '@/site/components/OpportunitiesTile';
    import { site } from '@/Site';
    import { getOppIdFromOppId, getProgramTypeFromOppId, getProductTypeFromOppId, ProgramType, EmailMultiChannelIds, DataListOptionToOppId, ProductType } from '@/constants';
    import { formatStatCode, getFieldsMapperFromColumns, beforeTableEncode } from '@/util'
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';

    const route = useRoute();
    const router = useRouter();

    // Components ----
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';
    import ConfirmationOverlay from '@/site/components/ConfirmationOverlay';

    // State ----
    const searchTerm = ref('subCode');
    const showOverlay = ref(false);
    const subCode = ref('');

    const search = new Search('bridge-cross-sell-summary', 'row', 'subCode', constants.DEFAULT_PAGE_SIZE);
    const searchDownload = new Search('bridge-cross-sell-summary', 'row', 'subCode', null);

    const fieldsMapper = getFieldsMapperFromColumns(columns);

    // Computed
    const listHeadline = computed(() => {
        switch (route.params.id) {
            case crossSellIds.AutoCrossSell:
                return "Cross-sell <b>auto to home</b>";
            case crossSellIds.HomeCrossSell:
                return "Cross-sell <b>home to auto</b>";
            case crossSellIds.UmbrellaCrossSell:
                return "Cross-sell <b>umbrella to auto</b>";
            case crossSellIds.SpecialtyCrossSell:
                return "Specialty Cross-sell";
            case crossSellIds.LandLordProtectionCrossSell:
                return "Landlord protection (LPP)";
            case crossSellIds.PetCrossSell:
                return "Pet Cross-sell";
            case crossSellIds.CustomAuto:
                return "<b>Auto to home</b>";
            case crossSellIds.CustomHome:
                return "<b>Home to auto</b>";
            case crossSellIds.CustomUmbrella:
                return "<b>Umbrella to auto</b>";
            default:
                return "No List Type Found"
        }
    });

    // Computed
    const filterInitiallValue = computed(() => {
        return getCrossSellType(route.params.id);
    });

    const enableStartOrder = computed(() => {
        return route.path.endsWith('cross-sell-home-to-auto') ||
            route.path.endsWith('cross-sell-auto-to-home') ||
            route.path.endsWith('cross-sell-umbrella-to-auto');
    });


    // Computed
    const opps = computed(() => {
        return searchDownload.results.rows;
    });

    const ownerMasterId = computed(() => {
        if (search && search.results && search.results.rows[0]) {
            return search.results.rows[0].ownerMasterId;
        }
        return '';
    });

    const totalOppCount = computed(() => {
        if (search && search.results && search.results.rows && search.results.rows.length > 0) {
            const counts = search.results.rows.map(r => r.opportunities);
            return counts.reduce((a, b) => a + b, 0);
        }
        return 0;
    });

    const showEnrollAction = computed(() => {
        const routeId = route.params.id;
        return routeId == crossSellIds.AutoCrossSell || routeId == crossSellIds.HomeCrossSell || routeId == crossSellIds.UmbrellaCrossSell;
    });

    // Methods ----
    function back() {
        router.go(-1);
    }

    async function confirmEnroll() {
        const response = await site.opportunities.setEnrollment(subCode.value, filterInitiallValue.value);
        if (response && response.succeeded) {
            search.refresh();
            site.toast.success("Successfully Enrolled!");
        }

        showOverlay.value = false;
    }

    function onEnrollClicked(code) {
        showOverlay.value = true;
        subCode.value = code;
    }

    function cancelEnroll() {
        showOverlay.value = false;
    }

    function getEnrollText(isEnrolled) {

        return isEnrolled ? "Enrolled" : "Not Enrolled";
    }

    async function onStartOrderClicked(agencyCode) {

        let derivedOppId = getOppIdFromOppId(route.params.id)



        const isEmailMultiChannelSupported = EmailMultiChannelIds.filter(em => em == route.params.id)?.length > 0;


        if (isEmailMultiChannelSupported) {
            router.push({
                name: 'SelectChannelPage',
                query: {
                    programType: getProgramTypeFromOppId(derivedOppId),
                    productType: getProductTypeFromOppId(derivedOppId),
                    oppType: filterInitiallValue.value,
                    statCode: agencyCode,
                    oppId: derivedOppId
                }
            });
        }
        else {
            if (derivedOppId === DataListOptionToOppId.PetCrossSell) {
                router.push({
                    name: 'ProductSearch',
                    query: { 'f-program-type': ProgramType.PET_INSURANCE, 'f-product-type': ProductType.PetInsurance, 'active-tab': TabIDs.CROSS_SELL_TAB_ID }
                });
            }
              else if (derivedOppId === crossSellIds.LandLordProtectionCrossSell) {
      router.push({
          name: 'SelectAssetPage',
          query: { 'programType': ProgramType.CROSS_SELL, 'productType': productTypes.LandLordProtection, 'oppId': crossSellTypes.LandLordProtectionCrossSell, 'isMultiChannel': 'false', 'channelType': ChannelTypes.DIRECT_MAIL }
      });
  }
  else if (derivedOppId === crossSellIds.CustomUmbrella) {
      router.push({
          name: 'SelectAssetPage',
          query: { 'programType': ProgramType.CROSS_SELL, 'productType': ProductType.UMBRELLA, 'oppId': crossSellTypes.UmbrellaCrossSell, 'isMultiChannel': 'false', 'channelType': ChannelTypes.DIRECT_MAIL }
      });
  }
   else if (derivedOppId === crossSellIds.CustomAuto) {
      router.push({
          name: 'SelectChannelPage',
          query: { 'programType': ProgramType.CROSS_SELL, 'productType': ProductType.AUTO, 'oppId': crossSellTypes.AutoCrossSell, 'isMultiChannel': 'false', 'channelType': ChannelTypes.DIRECT_MAIL }
      });
  }

   else if (derivedOppId === crossSellIds.CustomHome) {
       router.push({
          name: 'SelectChannelPage',
          query: { 'programType': ProgramType.CROSS_SELL, 'productType': ProductType.HOME, 'oppId': crossSellTypes.HomeCrossSell, 'isMultiChannel': 'false', 'channelType': ChannelTypes.DIRECT_MAIL }
      });
  }
            else {
                router.push({
                    name: 'SelectAssetPage',
                    query: {
                        programType: getProgramTypeFromOppId(derivedOppId),
                        productType: getProductTypeFromOppId(derivedOppId),
                        isMultiChannel: "false",
                        oppId: derivedOppId,
                        channelType: ChannelTypes.DIRECT_MAIL
                    }
                });
            }
        }

    }

    function getExportedData() {
        return opps.value.map(item => ({
            ...item,
            agencyAddress: `${item.agencyAddress1} ${item.agencyAddress2} ${item.agencyCity}, ${item.agencyState} ${item.agencyZip}`
        }));
    }

    async function exportCSV() {
        await setFilterValues();
        ExportParser().exportCSVFromJSON(getExportedData(), null, null, fieldsMapper, beforeTableEncode(columns));
    }

    async function exportXLS() {
        await setFilterValues();
        ExportParser().exportXLSFromJSON(getExportedData(), null, null, fieldsMapper, beforeTableEncode(columns));
    }
    async function setFilterValues() {
        const filterValues = search.getFilterValues("cross-sell-type-code");
        await searchDownload.setFilterValues("cross-sell-type-code", filterValues);
        await searchDownload.activate(route);
    }

    function sortSearch(value) {

        if (searchTerm.value.includes(value)) {

            if (searchTerm.value.includes('-desc')) {
                searchTerm.value = value;
            } else {
                searchTerm.value = value + '-desc';
            }

        } else {
            searchTerm.value = value;
        }


        search.setSortId(searchTerm.value);
    }



</script>

<template>
    <PageTemplate>

        <ConfirmationOverlay v-if="showOverlay">
            <template v-slot:headline>

                <h2 class="confirm">Are you sure you want to Enroll?</h2>

            </template>
            <template v-slot:buttons>

                <button class="btn btn-outline-primary me-5" @click="cancelEnroll">Cancel</button>
                <button class="btn btn-primary ms-5" @click="confirmEnroll">Enroll</button>

            </template>
        </ConfirmationOverlay>

        <div class="container cross-sell-view-summary">

            <GlobalTabBar :active-id="TabIDs.CROSS_SELL_TAB_ID" />

            <div class="row mt-4">

                <div class="col">
                    <h1 class="text-capitalize" v-html="listHeadline"></h1>
                    <div>Owner Master ID: {{formatStatCode(ownerMasterId)}}</div>
                </div>

            </div>
            <SearchContainer :search="search" default-view="list" :deep-link="false">

                <!-- Hidden but used for filtering on filterInitiallValue-->
                <TextBoxFilter v-show="false" filter-id="cross-sell-type-code" :initial-value="filterInitiallValue" />

                <div class="row">
                    <div class="d-flex">
                        <div class="me-auto">
                            Total Opportunities: <strong>{{ totalOppCount }} </strong> <a class="ms-3 no-underline" @click="back()">Back to <span v-html="listHeadline"></span> <i class="bi bi-chevron-right"></i></a>
                        </div>
                        <div>
                            <i class="bi bi-file-earmark-excel me-3 fs-1" title="Download in Excel format"  @click="exportXLS"></i>
                            <i class="bi bi-filetype-csv fs-1" title="Download in CSV format" @click="exportCSV"></i>
                        </div>
                        <div class="px-3">
                            <PageSizeList :options="pageSizeOptions" />
                        </div>
                        <div>
                            <div>
                                <span v-if="search.results.loaded && search.results.rows.length > 0">Showing {{ search.results.startIndex }} of {{ search.results.totalCount }}</span>
                            </div>
                            <PageSelector />
                        </div>
                    </div>
                </div>

                <div class="data-table">
                    <SearchTable :columns="columns" @sort-by="sortSearch">
                        <!-- Action column -->
                        <template v-slot:actions="row">
                            <div class="row">
                                <div class="col-4" v-if="showEnrollAction">
                                    <button v-if="!row.isEnrolled" @click="onEnrollClicked(row.subCode)" class="btn btn-primary btn-sm">Enroll</button>

                                    <span v-else class="text-muted">Enrolled</span>
                                </div>
                                <div class="col-4">
                                    <button v-if="!enableStartOrder"
                                            @click="onStartOrderClicked(row.subCode)"
                                            class="btn btn-primary btn-sm">
                                        Start Order
                                    </button>
                                </div>

                                <div class="col-4">
                                    <router-link :to="`/cross-sell/view-detail/${filterInitiallValue}/${row.subCode}?enrollText=${getEnrollText(row.isEnrolled)}`" class="no-underline fs-5">View List</router-link>
                                </div>
                            </div>
                        </template>
                        <template v-slot:subCode="row">
                            {{formatStatCode(row.subCode)}}
                        </template>
                        <!-- Action column -->
                        <template v-slot:agencyAddress="row">
                            <div>
                                {{row.agencyAddress1}}
                            </div>
                            <div>
                                {{row.agencyAddress2}}
                            </div>
                            <div>
                                {{row.agencyCity}}, {{row.agencyState}} {{row.agencyZip}}
                            </div>
                        </template>

                        <!-- Template for no results -->
                        <template #empty>
                            No
                            <span v-html="listHeadline"></span> Lists were found that match your criteria.
                        </template>

                        <!-- Template while results are initially loading -->
                        <template #loading>
                            <div class="row">
                                <div class="col text-center">
                                    <div class="spinner-border me-3" role="status"></div>
                                    <div class="spinner-border me-3" role="status"></div>
                                    <span class="fw-bold">Performing a search for the data</span>
                                    <div class="spinner-border mx-3" role="status"></div>
                                    <div class="spinner-border" role="status"></div>
                                </div>
                            </div>
                            <TableSkeleton />
                        </template>
                    </SearchTable>
                </div>

                <div class="row">
                    <div class="col text-end fit-content"><PageSelector /></div>
                </div>

            </SearchContainer>

        </div> <!-- End Container-->

    </PageTemplate>
</template>

<style lang="scss">
    .cross-sell-view-summary {
        .btn-sm {
            padding: 0.25rem 0.5rem;
            font-size: 0.75rem;
        }

        .bi {
            color: $color-2;
            cursor: pointer;
        }

        table td, table th {
            white-space: nowrap;
        }

        table td:first-of-type {
            min-width: 300px;
        }
    }
</style>