<script setup>
// HomePage
// Main home page

// Components
import PageTemplate from '@/template/PageTemplate';
import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
import { TabBar, ShowOnlyForRoles } from '@dd-nucleus/nucleus-vue';
import OpportunitiesTile from '@/site/components/OpportunitiesTile';
import ProspectingOpportunitiesTile from '@/site/areas/prospecting/components/ProspectingOpportunitiesTile';
//import HeroImageSlider from './components/HeroImageSlider';
import IconPanel from './components/IconPanel';
//import WhatToDoPanel from './components/WhatToDoPanel';
//import ExpandableLink from './components/ExpandableLink';
import LinkItem from './components/LinkItem';
import HeadlineWithIcon from './components/HeadlineWithIcon';
import IgniteProspectingPromo from '@/site/components/IgniteProspectingPromo';

// Imports
import { reactive, ref, computed, onMounted, watch } from 'vue';
import { site } from '@/Site';
import { ProgramType, TabIds } from '@/constants';
import { useRoute } from 'vue-router';
import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';
import { isNotEmpty } from '@/util';
import useEventsBus from '@/site/services/EventBus';

const { bus } = useEventsBus();

const route = useRoute();
const dataLoaderAnnouncement = ref(false);
const savedItems = reactive({ orderStates: [] });
const bannerData = reactive({ banners: [] });
let announcementToShow = reactive([]);

// For Adobe analytics
onMounted(async () => {
    await getSavedItems();
    await site.analyticsHandler.push({ "tileCode": "Home" });
    const response = await site.banners.getBanners();
    bannerData.banners = response;

    const responseAnnouncement = await site.announcement.getAnnouncementsForUser();
    if (responseAnnouncement.succeeded) {
        announcementToShow.push(...responseAnnouncement.announcements);
        dataLoaderAnnouncement.value = true;
    } else {
        site.handleApiError(responseAnnouncement);
        return null;
    }
    await site.analyticsHandler.push({ "tileCode": "Home" });   
});


// State
const growthTabs = reactive([
    {
        id: 'growth-cross-sell',
        title: 'Cross-sell'
    },
    {
        id: 'growth-prospecting',
        title: 'Prospecting & Campaigns'
    }
]);

const currentAgencyId = ref(site.agencyDetails.agencyDefaultId);
const selectedAgencyDetails = reactive(site.agencyDetails.selectedAgencyDetails);

// Computed
const isEmployee = computed(() => {
    return currentAgencyId.value == 0 ? true : false;
});

const selectedTab = computed(() => {
    switch (route.query.tab) {
        case ProgramType.PROSPECTING:
            return TabIds.PROSPECTING;
        default:
            return TabIds.CROSS_SELL;
    }
});

function isActive(id) {
    if (id == selectedTab.value) {
        return 'active show';
    }
}

async function getSavedItems() {
    savedItems.orderStates.length = 0;
    if (site.agencyDetails.defaultAgency.id > 0) {
        const response = await site.personalizationInfo.getSavedItems();
        if (response && response.length > 0) {
            savedItems.orderStates = response;
        }
    }
}

async function deleteOrderState(id) {
    const response = await site.personalizationInfo.deleteOrderState(id);
    if (response.succeeded) {
        const toDeleteOrderState = savedItems.orderStates.find(os => os.id == id);
        savedItems.orderStates = savedItems.orderStates.filter((item) => item.id != id);
        if (isNotEmpty(toDeleteOrderState.parentId)) {
            if (toDeleteOrderState.parentId == id) {
                savedItems.orderStates = savedItems.orderStates.filter(item => item.parentId != id);
            } else if (toDeleteOrderState.parentId != id) {
                savedItems.orderStates = savedItems.orderStates.filter(item => item.id != toDeleteOrderState.parentId);
            }
        }
    }
}

function resumeOrderState(orderState) {
    const parentId = orderState.parentId ? orderState.parentId : orderState.id;
    const orderStateId = orderState.parentId ? orderState.parentId : orderState.id;
    const channelType = orderState.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
    return `/personalization/step-1/${orderStateId}/${parentId}/${orderState.productId}/?edit=true&isSubscription=${orderState.product?.attributes?.isSubscription}&channelType=${channelType}`;
}

//watch
watch(() => bus.value.get('changeAgency'), async () => {
    await getSavedItems();
});
</script>

<template>
    <PageTemplate>
        <div class="container root-page home-page">

            <GlobalTabBar :active-id="TabIDs.HOME_TAB_ID" />

            <div class="root-page-container">

                <div class="row" v-if="dataLoaderAnnouncement">
                    <div class="col" v-if="announcementToShow.length">
                        <div class="announcements" v-for="announcement in announcementToShow" :key="announcement.id">
                            <h1 class="announcement-title" v-if="!!announcement.title">{{ announcement.title }}</h1>
                            <p class="announcement-message" v-if="!!announcement.body" v-html="announcement.body"></p>
                        </div>
                    </div>
                    <div class="col" v-else>
                        <h1>Prospect, cross-sell and market your agency</h1>
                        <p>
                            Take advantage of free and highly discounted resources and programs to fuel your agency's success.
                            To learn more about what's available, explore the tabs above.
                        </p>
                        <p>
                            To kickstart your agency's growth, start below with just a few clicks.
                        </p>
                        <br />
                        
                    </div>


                </div>


   





                <div class="row" v-if="!isEmployee">
                        <div class="col-md-8">
                            <HeadlineWithIcon iconPath="/images/icons/growth-opportunities-icon.png" title="My growth opportunities" />
                            <h1 class="growth"><i class="icon graph-up-arrow"></i> </h1>
                            <TabBar :tabs="growthTabs" :activeId="selectedTab" />

                            <div class="tab-content">
                                <div class="tab-pane fade" id="growth-cross-sell" :class="isActive(TabIds.CROSS_SELL)" role="tabpanel">
                                    <div class="sub-tab-container">
                                        <OpportunitiesTile filter-id="opportunities-cross-sell" :selectedAgencyDetails="selectedAgencyDetails" />

                                    </div>
                                </div>

                                <div class="tab-pane fade" id="growth-prospecting" :class="isActive(TabIds.PROSPECTING)" role="tabpanel">
                                    <div class="sub-tab-container">
                                        <ProspectingOpportunitiesTile filter-id="prospecting" />
                                    </div>
                                </div>

                                <!-- Removed the Retention tab content -->
                            </div>
                        </div>

                        <div class="col-md-4 d-flex align-items-start" style="padding-top: 0;">
                            <IgniteProspectingPromo />
                        </div>


                    </div>



                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <IconPanel title="My Links" icon="images/icons/my-links.png">
                                <LinkItem class="mb-3" title="My Favorite Marketing Materials" link="/account/favorites" :isExternal="true" :openNewWindow="false" />
                                <LinkItem class="mb-3" title="My Favorite Reports" link="/reports" :isExternal="true" :openNewWindow="false" />
                                <ShowOnlyForRoles :roles="site.userRoles.getRolesForAllAccess()">
                                    <LinkItem class="mb-3" title="My Order History/Lists" link="/account/order-history" :isExternal="true" :openNewWindow="false" />
                                </ShowOnlyForRoles>
                            </IconPanel>
                        </div>

                        <div class="col-12 col-sm-4">
                            <IconPanel title="My Items Saved for Later" icon="images/icons/documents-multiple.png">
                                <div v-if="savedItems.orderStates.length > 0">
                                    <template v-for="item in savedItems.orderStates.slice(0,2)" :key="item.id">
                                        <div class="row save-for-later my-1">
                                            <div class="col-11">
                                                <router-link :to="resumeOrderState(item)" class="product">{{ item.product.itemNumber }} - {{ item.product.name }}</router-link>
                                            </div>
                                            <div class="col-1">
                                                <div @click="deleteOrderState(item.id)" class="delete mt-1"><i class="bi bi-trash3"></i></div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="my-1" v-if="savedItems.orderStates.length > 2">
                                        <LinkItem style="font-size: 1rem" title="View All" link="/account/save-for-later" :isExternal="true" :openNewWindow="false" />
                                    </div>
                                </div>
                                <div v-else class="mx-2">
                                    You have no saved marketing materials at this time.
                                </div>
                            </IconPanel>
                        </div>

                        <div class="col-12 col-sm-4">
                            <IconPanel title="Top picks" icon="images/icons/lifetime-repair-guarrantee-checkmark.png">
                                <LinkItem class="mb-3" title="Auto Insurance Brochures" :link="{ path: '/products', query: { 'f-product-type': 'Auto', 'f-product-format': 'Brochures' }}" :isExternal="false" :openNewWindow="false" />
                                <LinkItem class="mb-3" title="Home Insurance Brochures" :link="{ path: '/products', query: { 'f-product-type': 'Home', 'f-product-format': 'Brochures' }}" :isExternal="false" :openNewWindow="false" />
                                <LinkItem title="View All" :link="{ path: '/products'}" :isExternal="false" :openNewWindow="false" />
                            </IconPanel>
                        </div>
                    </div>

                </div>
        </div>
    </PageTemplate>
</template>

<style lang="scss" scoped>
h1.growth {
    font-weight: $normal;
}

    .tab-content .cross-sell {
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
    }

.home-page {
    .save-for-later {
        p {
            font-size: 12px;
            margin: 0px;
            margin-bottom: 4px;
        }

        .product {
            cursor: pointer;
            font-size: 1.0rem;
            text-decoration: none;
            display: inline-block;
        }

        .delete {
            cursor: pointer;
            color: red;
            font-size: 1.0rem;
        }
    }
}
</style>
